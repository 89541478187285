@import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800');
* {
  box-sizing: border-box;
}

html {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body {
  background-image: url('../src/assets/pexels-erik-mclean-6275458.jpeg');
  background-size: cover;

  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: 'Fira Sans', sans-serif;
}


h1,h2,h3,h4,h5,h6,label,span {
  font-weight: 500;
  font-family: 'Fira Sans', sans-serif;
}
body, html,  #root {
  width: 100%;
  height: 100%;
}
.App
{
  width: 100%;
  height: 60vh;
}

.custom-control-label {
  font-weight: 400;
}

.custom {
  width: 200px;
}

img {
  max-width: 25%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#container {
  max-width: 450px;
}


#alert-img {
  padding-top: 20px;
  min-width: 200px;
  max-width: 200px;
}


#location-btn {
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 20px;
  letter-spacing: 4px;
  position: relative;
  background-color: #16a085;
  border: none;
  color: #fff;
  padding: 20px;
  width: 200px;
  text-align: center;
  transition-duration: 0.4s;
  overflow: hidden;
  box-shadow: 0 5px 15px #193047;
  border-radius: 4px;
}

#location-btn:hover, #city-btn:hover {
  background: #fff;
  box-shadow: 0px 2px 10px 5px #1abc9c;
  color: #000;
}

#location-btn:after {
  content: "";
  background: #1abc9c;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 5s
}

#location-btn:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s
}

#location-btn:focus { outline:0; } 

.alert-img {
  padding-top: 20px;
  min-width: 225px;
  max-width: 225px;
}